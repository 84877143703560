/**
 * Newsletter popup
 *  - Handles newsletter open popup and form submission, replaces content on success
 */
class NewsletterPopup {

    #suppressNewsletterPopupCloseEvent = false;

    constructor() {
        this.#bindEvents();
    }

    /**
     * Bind page events
     * 
     * @private
     * @returns {void}
     */
    #bindEvents() {
        // Handle newsletter form submission
        Soho.plugins.delegate(document, 'submit', '.js-submit-newsletter, .checkout-thanks-panel__newsletter', (e) => this.#handleSubmit(e));

        // Trigger newsletter popup after timeout
        if (document.cookie.indexOf('soho_npopup=1') < 0 && $('.popup--newsletter').length) {
            setTimeout(() => this.#openPopup(), 15000);
        }

        // Handle newsletter popup trigger
        if ($('.popup--newsletter').length) {
            $('.popup--newsletter').on('newsletter.popup', () => {
                this.#openPopup();
            });
        }

        // Handle manual popup trigger
        $('.js-newsletter-popup-trigger').on('click', (e) => {
            e.preventDefault();
            this.#openPopup();
        });
    }

    /**
     * Handle newsletter form submission
     *
     * @private
     * @param {*} e
     * @returns {void}
     */
    #handleSubmit(e) {
        e.preventDefault();
        const form = $(e.target);

        this.#suppressNewsletterPopupCloseEvent = true;
        const scope = form.attr('data-newsletter-scope') || form.attr('id') || 'unknown';

        // Add more specificity to the scope
        const resolvedScope = (scope === 'block') ? 'block--' + window.location.pathname : scope;

        $.ajax({
            url: '/newsletter',
            method: 'post',
            data: form.serialize(),
            success: (data) => this.#handleAjaxSuccess(data, form, resolvedScope)
        });
    }

    /**
     * Handle ajax success
     *
     * @private
     * @param {*} data 
     * @param {*} form 
     * @param {*} scope 
     * @returns {void}
     */
    #handleAjaxSuccess(data, form, scope) {
        const html = data.payload.html;
        $('input[type=email]', form).val("");
        $('input[type=email]', form).trigger('change');

        if ($.magnificPopup.instance.isOpen) {
            this.#updatePopupContent(html);
        } 

        if (data.payload.successful) {
            this.#setCookie('soho_npopup', '1', 365);
            Soho.GTM.trackEvent('Newsletter form', 'Subscribe', scope);
        }
    }

    /**
     * Updating the popup content
     *
     * @private
     * @param {*} html 
     * @returns {void}
     */
    #updatePopupContent(html) {
        $.magnificPopup.instance.content.replaceWith(html);

        const popupClose = document.querySelector('.js-popup--close');
        if (popupClose) {
            popupClose.onclick = $.magnificPopup.close;
        }
    }

    /**
     * Open newsletter popup
     *
     * @private
     * @param {*} content 
     * @returns {void}
     */
    #openPopup(content = $('.popup--newsletter')) {
        $.magnificPopup.open({
            items: {
                src: content,
                type: 'inline'
            },
            closeMarkup: '<button title="%title%" type="button" class="mfp-close mfp-close--dark" aria-label="Close">&#215;</button>',
            focus: '#newsletter_email',
            callbacks: {
                open: () => this.#onPopupOpen(),
                close: () => this.#onPopupClose()
            },
            mainClass: "mfp--newsletter",
            fixedContentPos: true
        });

        Soho.GTM.trackExperiment(Soho.newsletterOptimizeExperimentId, 0);
    }

    /**
     * Handle popup open event
     *
     * @private
     * @returns {void}
     */
    #onPopupOpen() {
        if (Soho.ContentSquare) {
            Soho.ContentSquare.trackPopupOpenPageview('newsletter');
        }

        if (Soho.adaptivePlaceholder) {
            Soho.adaptivePlaceholder();
        }
    }

    /**
     * Handle popup close event
     *
     * @private
     * @returns {void}
     */
    #onPopupClose() {
        if (!this.#suppressNewsletterPopupCloseEvent) {
            this.#setCookie('soho_npopup', '1', 7);
            Soho.GTM.trackEvent('Newsletter form', 'Closed', 'popup');
        }

        if (Soho.ContentSquare) {
            Soho.ContentSquare.trackPopupClosePageview();
        }
    }

    /**
     * Set a cookie
     *
     * @private
     * @param {*} name 
     * @param {*} value 
     * @param {*} days
     * @returns {void} 
     */
    #setCookie(name, value, days) {
        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    }
}

new NewsletterPopup();
